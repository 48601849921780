<template>
  <div class="col">
    <card>
      <div class="handle_titles mb-2">
        <span class="text-capitalize">Family Name:</span>
        <span>{{ getShowFamily.name }} </span>
      </div>
      <div class="handle_titles ">
        <span class="text-capitalize">Family code:</span>
        <span>{{ getShowFamily.family_code }} </span>
      </div>
    </card>
    <!-- Large Screen -->
    <server-table
      class="hidden-sm-only hidden-xs-only"
      :count="getShowFamilyCount"
      :DataItems="getShowFamilyMembers"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'Family'"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="first_name"
          :label="`${localization('First Name')}`"
        ></el-table-column>
        <el-table-column
          prop="last_name"
          :label="`${localization('Last Name')}`"
        >
        </el-table-column>
        <el-table-column prop="email" :label="`${localization('Email')}`">
        </el-table-column>

        <el-table-column prop="family_type" label="type">
          <template slot-scope="scope">
            <div>
              <span>{{ scope.row.family_type == 1 ? "Admin" : "Member" }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="actions_wrapper td-actions">
              <router-link
                :to="`/families/family-profile/${scope.row.user_id}`"
                v-tooltip.top-center="`${localization('Show Member')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
    <!-- Small Screen -->
    <server-table
      class="hidden-md-only hidden-lg-only hidden-xl-only"
      :count="getShowFamilyCount"
      :DataItems="getShowFamilyMembers"
      @Refresh="Refresh"
      @reset="filter = {}"
      :filter="filter"
      :title="'Family'"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="first_name"
          :label="`${localization('First Name')}`"
        ></el-table-column>
        <el-table-column
          prop="last_name"
          :label="`${localization('Last Name')}`"
        >
        </el-table-column>

        <el-table-column prop="" label="_" width="100">
          <template slot-scope="scope">
            <div class="actions_wrapper td-actions">
              <router-link
                :to="`/families/family-profile/${scope.row.user_id}`"
                v-tooltip.top-center="`${localization('Show Member')}`"
                class="btn btn-info btn-link btn-xs"
              >
                <i class="fa fa-eye"></i>
              </router-link>
            </div>
          </template>
        </el-table-column>
      </template>
    </server-table>
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
export default {
  data() {
    return {
      filter: {}
    };
  },
  mounted() {
    this.Refresh();
  },
  methods: {
    Refresh() {
      this.$store.dispatch("user/showFamily", {
        id: this.$route.params.id
      });
    }
  },
  computed: {
    getShowFamily() {
      return this.$store.getters["user/getShowFamily"];
    },
    getShowFamilyCount() {
      return this.$store.getters["user/getShowFamilyCount"];
    },
    getShowFamilyMembers() {
      return this.$store.getters["user/getShowFamilyMembers"];
    }
  },
  components: {
    ServerTable
  }
};
//
</script>
<style lang="scss" scoped>
.handle_titles {
  display: flex;
  gap: 8px;
}
</style>
